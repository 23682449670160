#rippl-root #select-charity-search .panel {
  padding: 0.5rem 0;
}
#rippl-root #select-charity-search .MuiGrid-container {
  margin-top: 0;
}
#rippl-root #select-charity-search .MuiCard-root {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  width: 50%;
  max-width: 6.5rem;
  margin: 0.5rem;
}
#rippl-root #select-charity-search .MuiCard-root .card-image {
  height: 5rem;
  background-color: #fff;
  font-size: 3rem;
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
}
#rippl-root #select-charity-search .MuiCard-root .card-image.missing {
  background-color: rgba(255, 255, 255, 0.15);
}
#rippl-root #select-charity-search .MuiCard-root .card-image img {
  width: 100%;
}
#rippl-root #select-charity-search .MuiCard-root .card-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 2;
  margin-top: 0.2rem;
  font-size: 0.7rem;
  line-height: 1.2em;
}
#rippl-root #select-charity-search .MuiCard-root .card-links {
  margin-top: 0.5rem;
  font-size: 0.7rem;
  cursor: pointer;
}
#rippl-root #select-charity-search .MuiButton-root.more {
  padding: 0 0.5rem;
  font-size: 0.7rem;
  text-decoration: underline;
}
#rippl-root #select-charity-search .MuiButton-root.select {
  margin-top: 0.3rem;
  padding: 0.2rem 1rem;
  font-size: 0.7rem;
  min-width: 0;
}
