#rippl-root #pledges .panel-list {
  margin-top: 2rem;
}
#rippl-root #pledges .panel .segment.primary .footer {
  white-space: nowrap;
}
@media screen and (max-width: 620px) {
  #rippl-root #pledges .segment.copy {
    display: none;
  }
}
