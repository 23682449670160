#rippl-root .view {
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
}
#rippl-root .view.left {
  text-align: left;
}
#rippl-root .view.left h1,
#rippl-root .view.left h2,
#rippl-root .view.left h3 {
  text-align: center;
}
#rippl-root .view.with-nav::before {
  content: "";
  display: table; /* Fallback */
  display: grid;
  height: 50px;
  width: 100%;
}
#rippl-root .view.long {
  justify-content: start;
}
#rippl-root .view > .inner {
  max-width: 550px;
}
#rippl-root .view.expand > .inner {
  width: 100%;
}
#rippl-root .view.wide > .inner {
  max-width: 750px;
}
#rippl-root .view.with-nav > .inner {
  padding-bottom: 1rem;
}
