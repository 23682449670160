#rippl-root .topnav {
  position: fixed;
  top: 0;
  text-align: right;
  padding: 0.2rem 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(23, 77, 132, 0.9);
  z-index: 999;
}
#rippl-root .topnav #logo {
  line-height: 0.5rem;
}
#rippl-root .topnav img {
  height: 1rem;
}
#rippl-root .topnav nav {
  width: 100%;
  font-size: 0.7rem;
}
#rippl-root .topnav nav a {
  text-transform: uppercase;
  text-decoration: none;
}
#rippl-root .topnav nav ul {
  list-style: none;
  margin: 0;
}
#rippl-root .topnav nav ul li {
  display: inline-block;
}
#rippl-root .topnav nav ul li {
  margin-left: 0.25rem;
}
#rippl-root .topnav nav ul li:first-child {
  margin-left: 0;
}
#rippl-root .topnav .MuiButton-root {
  font-size: 0.7rem;
  padding: 0.2rem 0.5rem;
  text-transform: none;
}
#rippl-root .topnav .MuiSvgIcon-root {
  width: 0.7em;
  height: 0.7em;
}
#rippl-root .topnav .MuiIconButton-root {
  padding: 6px;
}
#rippl-root .topnav nav .theme-toggle {
  margin-left: 0;
}
#rippl-root .topnav nav .theme-toggle svg {
  font-size: 0.8rem;
}
