html {
  font-size: 20px;
}
@media screen and (min-width: 320px) {
  html {
    font-size: calc(20px + 6 * ((100vw - 360px) / 500));
  }
}
@media screen and (min-width: 500px) {
  html {
    font-size: 22px;
  }
}
body#rippl {
  font-family: Raleway, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #295a8c;
}
body#rippl h1,
body#rippl h2,
body#rippl h3 {
  font-family: Courgette, cursive;
  font-weight: 400;
  margin: 0;
  line-height: 1.2em;
}
body#rippl h1 {
  font-size: 2rem;
}
body#rippl h2 {
  font-size: 1.5rem;
}
body#rippl h3 {
  font-size: 1.2rem;
}
body#rippl p {
  line-height: 1.4em;
}
body#rippl blockquote {
  margin: 1rem;
  padding: 0.5rem 0 0.5rem 1rem;
  border-left: 4px solid rgba(255, 255, 255, 0.2);
}
body#rippl a,
body#rippl a:link,
body#rippl a:hover,
body#rippl a:active,
body#rippl a:focus,
body#rippl a:visited {
  text-decoration: underline;
  color: #fff;
  background-color: transparent;
}
body#rippl .nowrap {
  white-space: nowrap;
}
body#rippl {
  height: 100vh;
}
body#rippl ::placeholder {
  color: rgba(255, 255, 255, 0.7);
}
body#rippl :-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
body#rippl ::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
body#rippl .text-left {
  text-align: left;
}
body#rippl .text-center {
  text-align: center;
}
body#rippl .subtext {
  font-size: 0.7rem;
  line-height: 1.2em;
}
body#rippl .smallish {
  font-size: 0.9rem;
}
body#rippl .small {
  font-size: 0.8rem;
}
body#rippl .large {
  font-size: 1.2rem;
}
body#rippl .supporting {
  color: rgba(255, 255, 255, 0.8);
}
body#rippl .highlighted {
  background-color: #4d94ce;
  padding: 0 0.25rem;
}
body#rippl .legal-info {
  text-align: left;
  font-size: 0.7rem;
}
body#rippl .legal-info h1,
body#rippl .legal-info h2,
body#rippl .legal-info h3,
body#rippl .legal-info h4 {
  font-family: Raleway, sans-serif;
  font-size: 1rem;
}
body#rippl input:-webkit-autofill,
body#rippl input:-webkit-autofill:hover,
body#rippl input:-webkit-autofill:focus,
body#rippl input:-webkit-autofill:active {
  -webkit-text-fill-color: #fff !important;
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}
body#rippl form {
  max-width: 350px;
  margin: 0 auto;
}
body#rippl form.full-width {
  max-width: none;
}
body#rippl form.full-width .input {
  width: 100%;
}
body#rippl form .legend {
  margin-top: 1rem;
}
body#rippl form fieldset {
  position: relative;
  border: none;
  margin: 0;
  padding: 0;
}
body#rippl form button {
  margin-top: 2rem;
}
body#rippl form.loading {
  opacity: 0.5;
}
body#rippl form .form-msg {
  margin-top: 1rem;
  font-size: 0.7rem;
}
body#rippl .input {
  position: relative;
  width: 12rem;
  margin: 30px auto 0;
}
body#rippl .input .help-block {
  font-size: 0.7rem;
  margin-top: 0.2rem;
}
body#rippl input,
body#rippl textarea {
  outline: none;
  background-color: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #fff;
  font-size: 0.8rem;
  color: #fff;
  padding: 0.2rem;
  width: 100%;
  font-family: sans-serif;
}
body#rippl .input.has-error input {
  border-color: #f00;
}
body#rippl .input.has-error .help-block {
  color: rgba(255, 255, 255, 0.9);
}
body#rippl form .spinner {
  display: none;
}
body#rippl form.loading .spinner {
  position: absolute;
  width: 100%;
  top: calc(50% - 30px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
body#rippl .MuiButton-root {
  font-size: 0.8rem;
  padding: 0.5rem 2rem;
  text-transform: none;
}
body#rippl .MuiButton-root.linkish {
  padding: 0;
  margin: 0;
  min-width: 0;
  text-decoration: underline;
  vertical-align: top;
  font-size: 1em;
  line-height: inherit;
}
body#rippl .MuiButton-root.linkish.thin {
  text-decoration: none;
  border-bottom: 1px solid #fff;
  border-radius: 0;
}
body#rippl .MuiButton-sizeSmall {
  font-size: 0.7rem;
  padding: 0.3rem 1.3rem;
}
body#rippl .MuiButton-contained {
  box-shadow: none;
}
body#rippl .MuiButton-containedPrimary:hover {
  background-color: rgb(22, 125, 125);
}
body#rippl .MuiButton-containedPrimary.Mui-disabled {
  color: rgba(255, 255, 255, 0.4);
  background-color: rgba(255, 255, 255, 0.2);
}
body#rippl .MuiIconButton-colorSecondary:hover {
  background-color: transparent;
}
body#rippl .MuiMenuItem-root {
  font-size: 0.7em;
}
body#rippl .MuiMenuItem-root svg {
  margin-right: 0.5rem;
}
body#rippl .MuiMenuItem-root img#rippl-cutout {
  position: relative;
  top: 0.1rem;
  width: 0.72rem;
}
body#rippl .MuiMenuItem-root .nav-icon {
  width: 1.4rem;
}
body#rippl .MuiGrid-container {
  margin-top: 1rem;
}
body#rippl .MuiPaper-rounded {
  border-radius: 8px;
}
body#rippl .MuiCard-root {
  background-color: rgba(255, 255, 255, 0.15);
  box-shadow: none;
}
body#rippl .MuiCard-root {
  padding: 0.7rem;
}
body#rippl .MuiTooltip-tooltip {
  font-size: 0.7rem;
  background-color: #424242;
}
body#rippl .MuiTooltip-arrow {
  color: #505050;
}
body#rippl .buzzword {
  font-weight: 600;
  font-size: 1.15em;
  letter-spacing: -0.02em;
  white-space: nowrap;
}
body#rippl fieldset .MuiGrid-container .MuiButton-root {
  flex-grow: 1;
  height: 4rem;
  padding: 0;
  margin: 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
}
body#rippl fieldset .MuiGrid-container .MuiButton-root.selected {
  background-color: rgba(255, 255, 255, 0.15);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
body#rippl .button-row .MuiButton-root {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
body#rippl .MuiAlert-root {
  display: flex;
  align-items: center;
}
body#rippl .MuiAlert-filledError {
  background-color: #c7443a;
}
body#rippl .MuiAlert-message {
  font-size: 0.7rem;
  padding: 0;
  line-height: 1.2em;
  text-align: left;
}
body#rippl .field-list {
  display: table;
}
body#rippl .field-list .field-row {
  margin-top: 0.5rem;
}
body#rippl .field-list .field-row:first-child {
  margin-top: 0;
}
body#rippl .field-list .field-name,
body#rippl .field-list .field-value {
  padding: 0 0.5rem;
}
body#rippl .field-list .field-name {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
}
body#rippl div.caret {
  position: absolute;
  top: 50%;
  right: -0.8rem;
  font-size: 0.8rem;
  opacity: 0.5;
  transform: translate(0, -50%);
}
body#rippl .dollar-sign {
  font-size: 0.8em;
  vertical-align: text-top;
}
body#rippl .number {
  font-family: "Source Sans Pro", sans-serif;
}
