#rippl-root #pledge #description.panel {
  margin-top: 1rem;
}
#rippl-root #pledge .segment {
  flex-grow: 1;
}
#rippl-root #pledge .panel .value {
  font-size: 0.8rem;
}
#rippl-root #pledge .panel .value.metric {
  font-size: 1.8rem;
}
#rippl-root #pledge .panel-list .panel .value.metric {
  font-size: 1.2rem;
}
#rippl-root #pledge .panel .segment.primary {
  max-width: 6rem;
}
#rippl-root #pledge #description.panel .segment.primary .value {
  font-size: 1rem;
}
#rippl-root #pledge #description.panel .segment .edit-icon {
  position: absolute;
  top: -0.25rem;
  right: 0;
}
#rippl-root #pledge #description.panel .segment .edit-icon .MuiIconButton-root {
  font-size: 0.7rem;
  color: rgba(255, 255, 255, 0.7);
}
#rippl-root #pledge #description.panel .segment .input {
  margin: 0;
}
#rippl-root #pledge #description.panel .segment .MuiButton-root {
  margin: 0.2rem 0 0 0;
  padding: 0 0.2rem;
}
#rippl-root #pledge #description.panel .segment form {
  position: relative;
}
#rippl-root #pledge #description.panel .segment form .hidden {
  visibility: hidden;
  height: 0px;
}
#rippl-root #pledge #description.panel .segment form .spinner {
  width: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.8);
}
#rippl-root #pledge #description.panel .segment .footer {
  white-space: nowrap;
}
#rippl-root #pledge .panel.segmented {
  padding-top: 0;
}
#rippl-root #pledge #donation.panel img {
  max-height: 3rem;
  max-width: 7rem;
  padding-top: 0.4rem;
}
#rippl-root #pledge #donation.panel .segment:last-child {
  max-width: 60%;
}
#rippl-root #pledge #donation.panel .segment .label {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
#rippl-root #pledge #donation.panel .value.metric {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#rippl-root #pledge #donation.panel .value svg {
  font-size: 1.8rem;
  margin: 0.5rem;
}
#rippl-root #pledge #donation.panel .value .MuiButton-root {
  text-decoration: underline;
}
#rippl-root #pledge #donation.panel .value .unselected {
  padding: 1rem 0;
}
#rippl-root #pledge #donation.panel .footer {
  font-size: 0.7rem;
}
#rippl-root #pledge #pledge.panel {
  margin-bottom: 1rem;
}
#rippl-root #pledge #child-acts.panel-list .segment.copy .value {
  font-size: 0.7rem;
}
#rippl-root #pledge #unknown.panel {
  font-size: 0.8rem;
}
