#rippl-root #edit-profile .panel {
  min-width: 320px;
}
#rippl-root #edit-profile .MuiFormControl-root {
  display: block;
  margin: 1rem auto 0 auto;
  padding: 0 0.2rem;
  width: 12rem;
}
#rippl-root #edit-profile .MuiFormLabel-root {
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.8rem;
}
#rippl-root #edit-profile .MuiFormControlLabel-label {
  font-size: 0.8rem;
}
#rippl-root #edit-profile .MuiRadio-colorSecondary.Mui-checked {
  color: rgba(255, 255, 255, 0.7);
}
#rippl-root #edit-profile .MuiSvgIcon-root {
  width: 1.2rem;
  height: 1.2rem;
}
#rippl-root #edit-profile .MuiFormGroup-root {
  margin-top: 0.2rem;
}
#rippl-root #edit-profile .MuiRadio-root {
  padding: 5px 9px;
}
#rippl-root #edit-profile .MuiButton-root {
  margin-top: 0;
}
