#rippl-root .impact-panel .segment {
  padding-top: 0.5rem;
}
#rippl-root .impact-panel .segment .value .click-target {
  display: block;
}
#rippl-root .impact-panel .segment {
  flex-grow: 1;
}
#rippl-root .impact-panel .segment .value.metric {
  font-size: 1.8rem;
  padding: 0 0.25rem;
}
#rippl-root .impact-panel .segment .value.metric .metric-delta {
  font-size: 0.8rem;
  color: #fff;
  margin-top: -0.25rem;
}
#rippl-root .impact-panel .segment .value.metric .metric-delta svg {
  margin-right: 0.25rem;
}
#rippl-root .impact-panel .segment .value.metric .metric-delta span.chip {
  font-family: "Source Sans Pro", sans-serif;
  padding: 0 0.75rem;
  border-radius: 16px;
  white-space: nowrap;
}
#rippl-root .impact-panel .segment .value.metric .metric-delta span.chip.up {
  background-color: #178484;
}
#rippl-root .impact-panel .segment .value.metric .metric-delta span.chip.flat {
  background-color: #757575;
}
#rippl-root .impact-panel .subheading svg {
  margin-right: 0.25rem;
}
#rippl-root .impact-panel .segment .footer {
  margin-top: 0.25rem;
}
