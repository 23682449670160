#rippl-root #fundraiser .segment {
  flex-grow: 1;
}
#rippl-root #fundraiser .panel .value.metric {
  font-size: 1.8rem;
}
#rippl-root #fundraiser #title.panel {
  margin-top: 1rem;
}
#rippl-root #fundraiser #title.panel .segment.primary {
  max-width: 10rem;
  justify-content: center;
}
#rippl-root #fundraiser #title.panel .segment.primary .value {
  white-space: normal;
}
#rippl-root #fundraiser #title.panel .segment.primary .footer {
  white-space: nowrap;
}
#rippl-root #fundraiser .charity-logo {
  margin-top: 1rem;
  max-height: 6rem;
  max-width: 12rem;
}
#rippl-root #fundraiser #title.panel #join-link.segment .MuiGrid-container {
  margin-top: 0;
}
#rippl-root #fundraiser #title.panel #join-link.segment .MuiCard-root {
  background-color: transparent;
}
#rippl-root #fundraiser #title.panel #join-link.segment {
  overflow: hidden;
}
#rippl-root #fundraiser #title.panel #join-link.segment .card-link .card-link {
  text-overflow: ellipsis;
  overflow: hidden;
}
#rippl-root #fundraiser .chart {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.8rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
#rippl-root #fundraiser .chart .recharts-default-tooltip li {
  margin: 0;
}
#rippl-root #fundraiser .no-chart {
  font-size: 0.8rem;
  margin: 2rem;
}
#rippl-root #fundraiser .rotate {
  display: none;
}
@media screen and (max-width: 620px) {
  #rippl-root #fundraiser .chart {
    display: none;
  }
  #rippl-root #fundraiser .launched .rotate {
    display: block;
  }
}
