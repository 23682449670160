#rippl-root #act #description.panel {
  margin-top: 1rem;
}
#rippl-root #act .segment {
  flex-grow: 1;
}
#rippl-root #act .panel .value {
  font-size: 0.8rem;
}
#rippl-root #act .panel .value.metric {
  font-size: 1.8rem;
}
#rippl-root #act .panel-list .panel .value.metric {
  font-size: 1.2rem;
}
#rippl-root #act .panel .segment.primary {
  max-width: 6rem;
}
#rippl-root #act #description.panel .segment.primary .value {
  font-size: 1rem;
}
#rippl-root #act .panel.segmented {
  padding-top: 0;
}
#rippl-root #act #donation.panel img {
  max-height: 3rem;
  max-width: 7rem;
  padding-top: 0.4rem;
}
#rippl-root #act #donation.panel .segment:last-child {
  max-width: 60%;
}
#rippl-root #act #donation.panel .value.metric {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#rippl-root #act #donation.panel .value svg {
  font-size: 1.8rem;
  margin: 0.5rem;
}
#rippl-root #act #donation.panel .value .MuiButton-root {
  text-decoration: underline;
}
#rippl-root #act #donation.panel .footer {
  font-size: 0.7rem;
}
#rippl-root #act #pledge.panel {
  margin-bottom: 1rem;
}
#rippl-root #act #child-acts.panel-list .segment .label {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
#rippl-root #act #child-acts.panel-list .segment.copy .value {
  font-size: 0.7rem;
}
#rippl-root #act .panel-nav {
  margin-top: -1.75rem;
  text-align: left;
}
#rippl-root #act .panel-nav .MuiButton-root {
  padding: 0 0.5rem;
  margin: 0;
  font-size: 0.8rem;
}
