#rippl-root .panel-list {
  position: relative;
}
#rippl-root .panel-list .panel {
  margin: 1rem 0;
}
#rippl-root .panel-list.loading {
  opacity: 0.5;
}
#rippl-root .panel-list .spinner {
  display: none;
}
#rippl-root .panel-list.loading .spinner {
  position: absolute;
  width: 100%;
  top: calc(50% - 30px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
