#rippl-root .rotate-phone-icon svg {
  height: 0.7rem;
  fill: #fff;
  opacity: 0.7;
  position: absolute;
  top: -1rem;
  right: 0;
}
@media screen and (min-width: 621px) {
  #rippl-root .rotate-phone-icon {
    display: none;
  }
}
