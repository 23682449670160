#rippl-root .breadcrumbs {
  text-align: left;
  font-size: 0.8rem;
}
#rippl-root .breadcrumbs .MuiButton-root {
  text-decoration: none;
}
#rippl-root .breadcrumbs svg {
  margin-right: 0.25rem;
  font-size: 0.7rem;
}
