#rippl-root #fundraisers .panel-list {
  margin-top: 2rem;
}
#rippl-root #fundraisers .panel .segment.primary {
  max-width: 10rem;
  flex-grow: 1;
}
#rippl-root #fundraisers .panel .segment.primary .value {
  white-space: normal;
}
#rippl-root #fundraisers .panel .segment.primary .footer {
  white-space: nowrap;
}
@media screen and (max-width: 620px) {
  #rippl-root #fundraisers #acts.segment {
    display: none;
  }
}
