#rippl-root .card-link .MuiCard-root {
  max-width: 350px;
  padding: 0.5rem 1rem;
}
#rippl-root .card-link .MuiCard-root .card-header {
  font-size: 0.7rem;
}
#rippl-root .card-link .MuiCard-root .qrcode {
  margin-bottom: 0.5rem;
}
#rippl-root .card-link .MuiCard-root .qrcode .copy {
  text-decoration: underline;
  cursor: pointer;
}
#rippl-root .card-link .MuiCard-root .qrcode svg {
  margin: 0.5rem 0.5rem 0;
  border: 6px solid #fff;
}
#rippl-root .card-link .MuiCard-root .card-link {
  position: relative;
  background-color: rgba(255, 255, 255, 0.15);
  padding: 0.5rem;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.9rem;
  cursor: pointer;
  overflow: auto;
}
#rippl-root .card-link .MuiCard-root .card-help {
  font-size: 0.6rem;
}
#rippl-root .card-link .MuiCard-root .copy-button {
  margin-top: 0.5rem;
}
#rippl-root .card-link .MuiCard-root .copy-button button {
  padding: 0.25rem 1.5rem;
}
#rippl-root .card-link .MuiCard-root .copy-button svg {
  margin-right: 0.5rem;
}
