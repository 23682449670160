#rippl-root .panel {
  margin: 2rem 0;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  position: relative;
}
#rippl-root .panel.filled {
  background-color: rgba(255, 255, 255, 0.1);
}
#rippl-root .panel.filled.with-heading {
  margin-top: 2.5rem;
}
#rippl-root .panel.loading {
  opacity: 0.5;
}
#rippl-root .panel .spinner {
  display: none;
}
#rippl-root .panel.loading .spinner {
  position: absolute;
  width: 100%;
  top: calc(50% - 30px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#rippl-root .panel.clickable {
  cursor: pointer;
}
#rippl-root .panel.left,
#rippl-root .panel.right {
  width: 80%;
  margin-top: 3rem;
}
#rippl-root .panel.left {
  margin-left: 0;
  margin-right: auto;
}
#rippl-root .panel.right {
  margin-right: 0;
  margin-left: auto;
}
#rippl-root .panel.alert {
  border-color: rgba(255, 255, 0, 0.5);
}
#rippl-root .panel .heading {
  position: absolute;
  top: -0.8rem;
  left: 0.5rem;
  background-color: #295a8c;
  padding: 0 0.2rem;
}
#rippl-root .panel .heading .help-icon {
  position: absolute;
  top: 0.25rem;
  right: -1rem;
  background-color: #295a8c;
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.7rem;
}
#rippl-root .panel.filled .heading {
  top: -1.5rem;
  background-color: transparent;
}
#rippl-root .panel.filled .heading .help-icon {
  background-color: transparent;
}
#rippl-root .panel .subheading {
  position: absolute;
  top: -1.1rem;
  right: 0.5rem;
  color: #fff;
  font-size: 0.7rem;
}
#rippl-root .panel .icon {
  position: absolute;
  top: -30px;
  right: -15px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: 2px solid #ddf0ff;
  background-color: #4d94ce;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#rippl-root .panel .icon svg {
  margin: 0 auto;
}
#rippl-root .panel .body {
  padding: 1rem;
}
#rippl-root .panel.text-left .body {
  text-align: left;
}
#rippl-root .panel .body p:first-child {
  margin-top: 0;
}
#rippl-root .panel .body p:last-child {
  margin-bottom: 0;
}
#rippl-root .panel.left .arrow {
  position: absolute;
  bottom: -2rem;
  right: -3rem;
  transform: rotate(15deg);
}
#rippl-root .panel.right .arrow {
  position: absolute;
  bottom: -1.5rem;
  left: -3.5rem;
  transform: rotate(-10deg);
}
#rippl-root .panel .arrow img {
  height: 8rem;
  opacity: 0.75;
}
#rippl-root .panel .section {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  position: relative;
  margin-bottom: 25px;
  padding-bottom: 25px;
}
#rippl-root .panel .section:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: 0;
}
#rippl-root .panel .section .label {
  font-size: 0.8rem;
}
#rippl-root .panel ul {
  font-size: 0.8rem;
  text-align: left;
  padding-right: 1rem;
}
#rippl-root .panel ul li {
  line-height: 1.2em;
  margin: 0.5rem 0;
}
#rippl-root .panel ul li .date {
  font-size: 0.7rem;
  color: rgba(255, 255, 255, 0.7);
  white-space: nowrap;
}
#rippl-root .panel.segmented {
  position: relative;
  display: flex;
  justify-content: space-between;
}
#rippl-root .panel .segment {
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
  font-size: 0.9rem;
  border-left: 1px solid rgba(255, 255, 255, 0.15);
}
#rippl-root .panel .segment:first-child {
  border: 0;
}
#rippl-root .panel .segment.completed {
  background-color: rgba(32, 132, 132, 0.3);
}
#rippl-root .panel .segment .label {
  font-size: 0.7rem;
  color: rgba(255, 255, 255, 0.8);
  white-space: nowrap;
}
#rippl-root .panel.with-heading .segment .label {
  padding-top: 0.2rem;
}
#rippl-root .panel .segment .footer {
  font-size: 0.65rem;
  color: rgba(255, 255, 255, 0.8);
  white-space: nowrap;
  line-height: 1.2em;
}
#rippl-root .panel .segment.nested {
  padding: 0;
}
#rippl-root .panel .segment.nested .segment {
  padding-top: 0;
  display: block;
}
#rippl-root .panel .segment.nested > .label {
  padding-top: 0.2rem;
}
#rippl-root .panel .segment.nested > .value {
  display: flex;
  flex-grow: 2;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
#rippl-root .panel .segment.primary {
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 7px 0 0 7px;
  max-width: 5rem;
}
#rippl-root .panel .segment.primary .value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#rippl-root .panel .segment .value.metric {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.2rem;
  flex-grow: 2;
}
#rippl-root .panel .segment.copy {
  padding: 0.25rem;
  font-size: 0.7rem;
  text-align: left;
  flex-grow: 2;
  min-width: 100px;
}
#rippl-root .panel .segment.copy .more-copy {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: underline;
  cursor: pointer;
}
@media screen and (max-width: 620px) {
  #rippl-root .panel .segment.primary {
    flex-grow: 4;
  }
  #rippl-root .panel .segment .footer {
    white-space: normal;
  }
}
#rippl-root .panel .segment.clickable {
  position: relative;
}
#rippl-root .panel .segment.clickable .value {
  cursor: pointer;
}
#rippl-root .panel .segment.clickable .caret {
  right: 0.5rem;
}
