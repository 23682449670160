#rippl-root #ripple.view > .inner {
  width: 100%;
}
#rippl-root #ripple #tree-view {
  width: 100%;
  padding-top: 0.6rem;
  margin-bottom: 0;
  border-radius: 8px 8px 0 0;
  border-bottom: none;
}
#rippl-root #ripple #impact {
  margin: 0;
  border-radius: 0 0 8px 8px;
}
#rippl-root #ripple .panel-list .segment {
  flex-grow: 1;
}
#rippl-root #ripple .panel-list .segment.primary {
  max-width: none;
}
#rippl-root #ripple .panel .segment .label {
  white-space: normal;
}
#view-desc {
  margin-top: 1rem;
}
#view-desc > div {
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 0.7rem;
}
#view-desc svg {
  width: 0.9rem;
  height: 0.9rem;
  margin-right: 0.25rem;
}
