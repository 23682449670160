#rippl-root #pif-land img.logo {
  height: 1rem;
}
#rippl-root #pif-land .MuiCard-root {
  margin-top: 1rem;
}
#rippl-root #pif-land .MuiCard-root p:first-child {
  margin-top: 0;
}
#rippl-root #pif-land .MuiCard-root p.small {
  color: rgba(255, 255, 255, 0.8);
}
#rippl-root #pif-land #self-alert {
  margin-top: 0;
}
#rippl-root #pif-land .charity-logo {
  max-height: 4rem;
  max-width: 8rem;
}
