#rippl-root #about .panel {
  margin-top: 0;
}
#rippl-root #about h2 {
  margin: 0;
}
#rippl-root #about p {
  font-size: 0.9rem;
}
#rippl-root #about p:first-child {
  margin-bottom: 0.5rem;
}
