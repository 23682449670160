#rippl-root #new-ripple #swipe-icon {
  margin-top: 1rem;
  margin-bottom: -0.8rem;
  height: 0.6rem;
  opacity: 0.7;
}
#rippl-root #new-ripple .carousel-container {
  position: relative;
}
#rippl-root #new-ripple .slide-nav {
  position: absolute;
  top: 40%;
  opacity: 0.5;
}
#rippl-root #new-ripple #slide-left {
  left: -3rem;
}
#rippl-root #new-ripple #slide-right {
  right: -3rem;
}
#rippl-root #new-ripple #pif {
  margin-top: 1rem;
}
#rippl-root #new-ripple .charity-logo {
  max-height: 4rem;
  max-width: 8rem;
}
@media screen and (max-width: 699px) {
  #rippl-root #new-ripple .slide-nav {
    display: none;
  }
}
@media screen and (min-width: 700px) {
  #rippl-root #new-ripple #swipe-icon {
    display: none;
  }
}
