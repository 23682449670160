#rippl-root #faq #questions {
  margin-top: 2rem;
}
#rippl-root #faq #questions .MuiExpansionPanel-root {
  background-color: transparent;
  box-shadow: none;
}
#rippl-root #faq #questions .MuiExpansionPanel-root:before {
  background-color: transparent;
}
#rippl-root #faq #questions .MuiExpansionPanel-root.Mui-expanded {
  background-color: rgba(255, 255, 255, 0.1);
}
#rippl-root #faq #questions .MuiExpansionPanelSummary-content {
  justify-content: left;
  text-align: left;
}
#rippl-root #faq #questions .MuiExpansionPanelDetails-root {
  font-size: 0.8rem;
  text-align: left;
}
#rippl-root #faq #questions .MuiExpansionPanelDetails-root p:first-child {
  margin-top: 0;
}
@media screen and (max-width: 620px) {
  #rippl-root #faq #questions .MuiExpansionPanelSummary-content {
    justify-content: center;
    text-align: center;
  }
}
#rippl-root #faq #questions .spinner {
  float: left;
  transform: scale(0.5);
}
#rippl-root #faq p#contact {
  max-width: 500px;
  margin: 2rem auto;
}
