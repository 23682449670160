#rippl-root #pif-donation div#more {
  max-width: 10rem;
  margin: 0 auto 2rem auto;
}
#rippl-root #pif-donation div#more input {
  font-size: 1.2rem;
}
#rippl-root #pif-donation .input-group {
  margin-top: 1rem;
}
#rippl-root #pif-donation .input-group label {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
}
#rippl-root #pif-donation .input-group input {
  width: 4rem;
  text-align: center;
}
#rippl-root #pif-donation button#more {
  margin-top: 0;
  padding: 0.1rem 0.7rem;
  text-decoration: underline;
}
