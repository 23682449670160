#rippl-root #invite-land img.logo {
  height: 1rem;
}
#rippl-root #invite-land button {
  margin-bottom: 1rem;
}
#rippl-root #invite-land .MuiCard-root p {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.8);
}
