#rippl-root #select-charity-confirm .MuiCard-root {
  max-width: 200px;
}
#rippl-root #select-charity-confirm .MuiCard-root img {
  max-width: 150px;
}
#rippl-root #select-charity-confirm .MuiCard-root p {
  margin: 0.2rem;
  font-size: 0.8rem;
}
