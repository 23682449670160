body#rippl.light {
  background-color: #f8f9fa;
}
body#rippl.light h1,
body#rippl.light h2,
body#rippl.light h3 {
  color: #4d94ce;
}
body#rippl.light a,
body#rippl.light a:link,
body#rippl.light a:hover,
body#rippl.light a:active,
body#rippl.light a:focus,
body#rippl.light a:visited {
  color: #295a8c;
}
body#rippl.light input:-webkit-autofill,
body#rippl.light input:-webkit-autofill:hover,
body#rippl.light input:-webkit-autofill:focus,
body#rippl.light input:-webkit-autofill:active {
  -webkit-text-fill-color: #555 !important;
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

body#rippl.light #rippl-root .topnav {
  box-shadow: none;
}
body#rippl.light #rippl-root .view {
  color: #555;
}
body#rippl.light #rippl-root .panel {
  border-color: rgba(0, 0, 0, 0.2);
}
body#rippl.light #rippl-root .panel.filled {
  background-color: #fff;
}
body#rippl.light #rippl-root .panel.alert {
  border-color: rgba(0, 0, 0, 0.2);
}
body#rippl.light #rippl-root .panel-list .panel {
  background-color: #fff;
}
body#rippl.light #rippl-root .panel .heading {
  background-color: #f8f9fa;
}
body#rippl.light #rippl-root .panel .segment {
  border-color: rgba(0, 0, 0, 0.15);
}
body#rippl.light #rippl-root .panel .segment.primary {
  background-color: #4d94ce;
  color: #fff;
}
body#rippl.light #rippl-root .panel .segment.completed {
  background-color: rgba(32, 132, 132, 0.15);
}
body#rippl.light #rippl-root .panel .segment .footer {
  color: #888;
}
body#rippl.light #rippl-root .panel .segment.primary .footer,
body#rippl.light #rippl-root .panel .segment.primary .label {
  color: rgba(255, 255, 255, 0.8);
}
body#rippl.light #rippl-root .panel .segment .label {
  color: #888;
}
body#rippl.light #rippl-root .panel .segment.nested > .value {
  border-color: rgba(0, 0, 0, 0.15);
}
body#rippl.light #rippl-root .MuiButton-root.linkish {
  color: #295a8c;
}
body#rippl.light .MuiButton-root.linkish.thin {
  border-bottom: 1px solid #295a8c;
}
body#rippl.light #rippl-root .panel .heading .help-icon {
  color: rgba(81, 149, 204, 0.75);
  background-color: #f8f9fa;
}
body#rippl.light #rippl-root .cause-icons {
  opacity: 1;
  color: rgba(81, 149, 204, 0.75);
}
body#rippl.light #rippl-root .value.metric .metric-delta span.chip.up {
  background-color: rgba(32, 132, 132, 0.75);
}
body#rippl.light #rippl-root .value.metric .metric-delta span.chip.flat {
  background-color: #aaaaaf;
}
body#rippl.light #rippl-root .panel ul li .date {
  color: #888;
}
body#rippl.light #rippl-root #home h1 {
  text-shadow: none;
}
body#rippl.light #rippl-root #home #pif {
  filter: grayscale(100%) brightness(1.75);
}
body#rippl.light #rippl-root #home #see .MuiButton-label {
  color: #555;
  border-color: #555;
}
body#rippl.light .supporting {
  color: #888;
}
body#rippl.light #rippl-root .panel .arrow {
  filter: grayscale(100%) brightness(0.75);
}
body#rippl.light #rippl-root .panel .icon {
  color: #fff;
  border-color: rgba(0, 0, 0, 0.2);
}
body#rippl.light #rippl-root .input.floating label {
  color: #888;
}
body#rippl.light input,
body#rippl.light textarea {
  border-color: rgba(0, 0, 0, 0.2);
  color: #555;
}
body#rippl.light .input.has-error .help-block {
  color: #e64343;
}
body#rippl.light .MuiButton-root {
  color: #555;
}
body#rippl.light .MuiButton-containedPrimary,
body#rippl.light .topnav .MuiButton-root {
  color: #fff;
}
body#rippl.light .MuiButton-containedPrimary.Mui-disabled {
  color: rgba(0, 0, 0, 0.4);
  background-color: rgba(37, 150, 150, 0.2);
}
body#rippl.light fieldset .MuiGrid-container .MuiButton-root {
  color: #555;
  border-color: rgba(0, 0, 0, 0.2);
}
body#rippl.light fieldset .MuiGrid-container .MuiButton-root.selected {
  background-color: rgba(81, 149, 204, 0.1);
}
body#rippl.light form .powered-by {
  filter: grayscale(100%) brightness(0.5);
}
body#rippl.light #rippl-root #pif-billing div#card-element {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
body#rippl.light .MuiCard-root {
  background-color: #4d94ce;
}
body#rippl.light #rippl-root .lds-ring div {
  border-color: #4d94ce transparent transparent transparent;
}
body#rippl.light #rippl-root #select-charity-land .panel p {
  color: #555;
}
body#rippl.light #rippl-root #select-charity-search .MuiCard-root {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: #555;
}
body#rippl.light #rippl-root .rotate-phone-icon svg {
  fill: rgba(81, 149, 204, 0.75);
}
body#rippl.light #rippl-root #description.panel .edit-icon .MuiIconButton-root {
  color: rgba(81, 149, 204, 0.75);
}
body#rippl.light .field-list .field-name,
body#rippl.light #rippl-root #edit-profile .MuiFormLabel-root {
  color: #888;
}
body#rippl.light #rippl-root .MuiRadio-colorSecondary.Mui-checked,
body#rippl.light #rippl-root .MuiRadio-colorSecondary {
  color: rgba(81, 149, 204, 0.75);
}
body#rippl.light #rippl-root #login #forgot .MuiButton-root {
  color: #888;
}
body#rippl.light #rippl-root #ripple #view-desc > div {
  color: #888;
}
body#rippl.light #rippl-root #pif-billing .help-block.with-errors {
  color: #e22112;
}
body#rippl.light #rippl-root #faq #questions .MuiExpansionPanelDetails-root,
body#rippl.light #rippl-root #faq #questions .MuiExpansionPanelSummary-content {
  color: #555;
}
body#rippl.light
  #rippl-root
  #faq
  #questions
  .MuiExpansionPanel-root.Mui-expanded {
  background-color: #fff;
}
body#rippl.light #rippl-root #new-ripple .slide-nav {
  color: #555;
}
body#rippl.light
  #rippl-root
  #fundraiser
  #join-link.segment
  .card-link
  .card-link {
  background-color: rgba(81, 149, 204, 0.75);
}
body#rippl.light #rippl-root #fundraiser #join-link.segment .card-help {
  color: #888;
}
