#rippl-root #dashboard.view > .inner {
  width: 100%;
}
#rippl-root #dashboard .MuiGrid-container {
  margin-top: 0;
  flex-wrap: nowrap;
}
#rippl-root #dashboard .MuiCard-root {
  width: 50%;
  max-width: 8rem;
  margin: 0.5rem;
}
#rippl-root #dashboard .panel .icon {
  margin: 0 auto;
  top: inherit;
  right: 50%;
  bottom: -25px;
  transform: translate(50%, 0) scale(0.8);
  border: none;
}
#rippl-root #dashboard #pledges.panel .segment .value .click-target {
  display: block;
}
#rippl-root #dashboard #pledges.panel .segment {
  flex-grow: 1;
}
#rippl-root #dashboard #pledges.panel .segment .value.metric {
  font-size: 1.8rem;
}
#rippl-root #dashboard #ripple-effect {
  margin-bottom: 0;
  border-radius: 8px 8px 0 0;
  border-bottom: none;
  padding-top: 0.5rem;
}
#rippl-root #dashboard #total-impact.with-graphic {
  margin: 0;
  border-radius: 0 0 8px 8px;
}
