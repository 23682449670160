#rippl-root #home .hero {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#rippl-root #home h1 {
  margin-bottom: 1rem;
  text-shadow: 5px 5px 5px #295a8c, -5px -5px 5px #295a8c;
}
@media screen and (max-width: 500px) {
  #rippl-root #home h1 {
    font-size: 1.7rem;
  }
}
#rippl-root #home #pif {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90vw;
  max-width: 800px;
  opacity: 0.5;
  z-index: -999;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 700px) {
  #rippl-root #home #pif {
    display: none;
  }
}
#rippl-root #home #how-it-works {
  padding-bottom: 2rem;
}
#rippl-root #home #see {
  margin-top: 2rem;
}
#rippl-root #home #see .MuiButton-label {
  line-height: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#rippl-root #home #see .MuiButton-root {
  font-size: 1rem;
}
