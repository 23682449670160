#rippl-root #profile .panel {
  min-width: 320px;
}
#rippl-root #profile #forgot {
  margin-top: 0.5rem;
}
@media screen and (max-width: 500px) {
  #rippl-root #profile .field-name {
    font-size: 0.7rem;
  }
  #rippl-root #profile .field-value {
    font-size: 0.8rem;
  }
}
