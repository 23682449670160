#rippl-root #pif-billing div#platform-donation {
  margin: 2rem auto 0 auto;
  width: 15rem;
}
#rippl-root #pif-billing div#platform-donation .MuiFormControlLabel-label {
  font-size: 0.7rem;
  line-height: 1.2em;
  text-align: left;
}
#rippl-root #pif-billing div#card-group .input {
  width: 14rem;
}
#rippl-root #pif-billing div#card-element {
  border-bottom: 1px solid #fff;
}
#rippl-root #pif-billing div#powered-by img {
  width: 120px;
}
#rippl-root #pif-billing .panel.alert {
  margin-bottom: 0;
}
#rippl-root #pif-billing .MuiButton-root {
  margin-top: 0;
}
#rippl-root #pif-billing #amount {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.2rem;
}
#rippl-root #pif-billing #pif-self {
  margin-bottom: 1rem;
}
#rippl-root #pif-billing .help-block.with-errors {
  color: #ff8278;
}
#rippl-root #pif-billing #back.MuiButton-root {
  margin-top: 0.25rem;
  padding: 0 2rem;
}
