#rippl-root .input.floating label {
  pointer-events: none;
  position: absolute;
  top: 0.2rem;
  left: 4px;
  transition: all 0.2s ease;
  transform-origin: top left;
  white-space: nowrap;
  margin: 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.8rem;
}
#rippl-root .input.floating.float label {
  transform: translateY(-1rem);
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.7rem;
}
